import React from 'react';
import Container from '../../../../Shared/Container/Container';

const CLIENTS_LOGO_DATA = [
  {
    src: require('../../../../../assets/images/connect/python/client-logos/bed-bath-beyond.png')
      .default,
    alt: 'Bed Bath and Beyond',
  },
  {
    src: require('../../../../../assets/images/connect/python/client-logos/decorist.png').default,
    alt: 'Decorist',
  },
  {
    src: require('../../../../../assets/images/connect/python/client-logos/raven.png').default,
    alt: 'Raven',
  },
  {
    src: require('../../../../../assets/images/connect/python/client-logos/lifeprint.png').default,
    alt: 'Lifeprint',
  },
  {
    src: require('../../../../../assets/images/connect/python/client-logos/stacks.png').default,
    alt: 'Stacks',
  },
];

function ClientsLogoSection() {
  return (
    <section className="connect-python__clients-logo-section">
      <Container>
        <div className="connect-python__clients-logo-container">
          {CLIENTS_LOGO_DATA.map(({src, alt}, i) => (
            <div key={i} className="connect-python__clients-logo-img-container">
              <img className="connect-python__clients-logo-img" src={src} alt={alt} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default ClientsLogoSection;
