import React, {useState, useCallback} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../../../assets/css/connect.css';
import Footer from '../../../Shared/Footer';
import withUTMParams from '../../../HOC/withUTMParams';
import HeadingSection from '../../Section/HeadingSection';
import GrowthPartnerSection from '../../Section/GrowthPartnerSection';
import BuildTeamSection from '../../Section/BuildTeamSection';
import AwardsSection from '../../Section/AwardsSection';
import ClientsTestimonialSection from '../../Section/ClientsTestimonialSection';
import ContactUsSection from '../../Section/ContactUsSection';
import ClientsLogoSection from './Section/ClientsLogoSection';
import OurPortfolioSection from './Section/OurPortfolioSection';
import BlogPostsSection from '../../Section/BlogPostsSection';
import WhyClientPreferUsSection from '../../Section/WhyClientPreferUsSection';
import OurWebExpertiseSection from '../Section/OurWebExpertiseSection';
import IndustryVerticalsSection from '../Section/IndustryVerticalsSection';
import CookieBanner from '../../../Shared/CookieBanner';

function ConnectPythonPage({
  location,
  metaTitle,
  metaDescription,
  pageviewTitle,
  title,
  subtitles,
  variant = 'A',
}) {
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    ReactGA.set({
      title: variant === 'A' ? pageviewTitle : `${pageviewTitle}.B`,
    });
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeadingSection title={title} subtitles={subtitles} variant={variant} />
      <ClientsLogoSection />
      <BuildTeamSection />
      <WhyClientPreferUsSection />
      <OurWebExpertiseSection />
      <IndustryVerticalsSection />
      <OurPortfolioSection />
      <GrowthPartnerSection />
      <BlogPostsSection />
      <AwardsSection />
      <ClientsTestimonialSection />
      <ContactUsSection />
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      <Footer showContactSection={false} showPortfolioSection={false} />
    </div>
  );
}

export default withUTMParams(ConnectPythonPage);
