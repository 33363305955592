import React from 'react';
import ProjectCard from '../../../../Shared/ProjectCard/ProjectCard';
import Container from '../../../../Shared/Container/Container';

const PROJECTS = [
  {
    id: 'raven',
    featuredImageSrc: 'raven.png',
    logoSrc: require('../../../../../assets/images/portfolio/projects/logos/raven.png').default,
    projectDescription:
      'Raven is a platform for Real Estate Agents, by Real Estate Agents, who want to replace big brokerages taking huge money off Agent’s Leads and their commissions.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
  {
    id: 'life-print',
    featuredImageSrc: 'life-print.png',
    logoSrc: require('../../../../../assets/images/portfolio/projects/logos/life-print.png')
      .default,
    projectDescription:
      'Print living photos on the go with Lifeprint. A social combination of AR and portable printers to share your memories all over the world.',
    projectName: 'life-print',
    categories: {
      solutions: [
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'Google Application Engine',
          label: 'Google Application Engine',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
      ],
    },
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.png',
    logoSrc: require('../../../../../assets/images/portfolio/projects/logos/decorist.png').default,
    projectDescription:
      'Decorist’s innovative room builder application that allows designers to get photorealistic renders of rooms in record time.',
    projectName: 'decorist',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Unity', label: 'Unity'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Jenkins', label: 'Jenkins'},
      ],
    },
  },
  {
    id: 'bed-bath-beyond',
    caseStudyUrl: '',
    featuredImageSrc: 'bed-bath-beyond.png',
    logoSrc: require('../../../../../assets/images/portfolio/projects/logos/bed-bath-beyond.png')
      .default,
    projectDescription:
      "Bed Bath and Beyond's app lets end-users visualize products by virtually placing them in their own room, providing a personalized and interactive shopping experience.",
    projectName: 'bed-bath-beyond',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Lifestyle', label: 'Lifestyle'},
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'ARKit', label: 'ARKit'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
];

function OurPortfolioSection() {
  return (
    <section className="connect-python__our-portfolio-section">
      <Container>
        <div className="connect-python__our-portfolio-heading">See our Portfolio</div>
        <p className="connect-python__our-portfolio-description">
          For over 10 years, we’ve worked across multiple verticals and a range of services to
          create engaging and innovative digital experiences.
        </p>
        <div className="connect-python__our-portfolio-projects-container">
          {PROJECTS.map(project => (
            <div key={project.id} className="connect-python__our-portfolio-project-container">
              <ProjectCard
                {...project}
                featuredImageLoading="lazy"
                projectDescriptionClass="connect-python__our-portfolio-project-description"
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default OurPortfolioSection;
