import React from 'react';
import * as classes from './TechnologiesCard.module.css';

function TechnologiesCard({title, logos}) {
  return (
    <div className={classes.card}>
      <div className={classes.title}>{title}</div>
      <div className={classes.logos}>
        {logos.map((logo, index) => (
          <div key={index} className={classes.logoContainer}>
            <img
              className={classes.logo}
              key={`logo-${logo.src}`}
              src={
                require(`../../../../assets/images/connect/web/technologies/${logo.src}`).default
              }
              alt={logo.title}
            />
            <div className={classes.logoTitle}>{logo.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TechnologiesCard;
