import React from 'react';
import PythonConnectPage from '../../../components/Connect/Web/Python/index';

const subtitles = {
  A: (
    <>
      Tintash is a mobile and web development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B:
    'Leverage our Python development talent to elevate your project. We help you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <PythonConnectPage
      variant={pageContext.variant}
      location={location}
      title="Top 1% Python Development Company"
      subtitles={subtitles}
      metaTitle="Top 1% Web Python Development Company | Tintash"
      metaDescription="Tintash is an industry leading Web Application development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Web/Python.Development"
    />
  );
}

export default index;
